import { createMedia } from '@artsy/fresnel'

/*
Most common breakpoints
480px
768px
1024px
1280px
1366px
1440px
1920px
2560px+
*/

const AppMedia = createMedia({
  breakpoints: {
    sm: 0,
    lg: 1025,
  },
})

// Generate CSS to be injected into the head
export const mediaStyle = AppMedia.createMediaStyle()
export const { Media, MediaContextProvider } = AppMedia
